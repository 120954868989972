import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 893.000000 517.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,517.000000) scale(0.100000,-0.100000)" >
				<path d="M4277 4143 l-57 -4 2 -897 3 -897 98 -3 97 -3 0 178 0 179 58 57 59
58 99 -122 c54 -67 113 -139 129 -159 113 -140 168 -205 177 -209 11 -4 66 46
109 101 l29 36 -200 246 -201 246 196 191 c107 106 198 202 201 215 15 59 -35
124 -95 124 -45 0 -36 7 -321 -270 -122 -118 -225 -216 -231 -218 -5 -2 -9
115 -9 305 0 354 4 381 71 457 77 88 188 122 292 92 72 -21 180 -95 275 -188
230 -223 330 -538 277 -868 -49 -301 -255 -574 -542 -719 -393 -198 -905 -85
-1190 261 -82 101 -83 107 -83 492 1 299 2 329 16 311 14 -19 116 -163 227
-323 50 -71 78 -92 125 -92 40 1 62 24 141 145 l71 109 0 173 c0 95 -2 173 -5
173 -2 0 -52 -73 -110 -161 -59 -89 -110 -158 -114 -153 -7 8 -205 290 -333
476 -55 78 -73 93 -118 93 -30 0 -47 -8 -70 -30 l-30 -29 2 -567 3 -567 60
-88 c156 -231 411 -411 680 -479 206 -53 359 -55 559 -10 224 51 401 147 570
313 463 451 474 1198 24 1663 -129 133 -312 249 -484 307 -118 40 -316 64
-457 55z"/>
<path d="M3328 1582 c-16 -3 -18 -18 -18 -148 l0 -144 45 0 44 0 3 103 3 102
63 -102 c56 -92 66 -103 92 -103 25 0 35 11 86 95 32 52 62 95 66 95 4 0 8
-43 8 -95 l0 -95 50 0 51 0 -3 147 -3 147 -69 1 -69 0 -51 -84 c-28 -47 -56
-86 -61 -88 -6 -2 -32 33 -58 77 -26 43 -53 83 -60 87 -12 8 -86 11 -119 5z"/>
<path d="M4322 1580 c-52 -12 -73 -37 -70 -83 5 -63 33 -77 167 -86 118 -8
146 -20 116 -50 -33 -34 -185 -21 -185 15 0 10 -15 14 -55 14 -60 0 -67 -9
-39 -48 31 -44 70 -56 194 -56 154 0 200 23 200 103 0 60 -44 81 -188 88 -78
5 -105 10 -109 20 -3 8 0 19 8 24 23 15 133 11 155 -6 25 -19 124 -20 124 -2
0 22 -46 58 -87 68 -46 10 -185 10 -231 -1z"/>
<path d="M4693 1574 c-3 -8 -3 -53 -1 -99 7 -99 25 -132 93 -165 62 -30 208
-26 252 6 59 44 73 78 73 180 l0 94 -50 0 -50 0 0 -88 c0 -81 -2 -91 -24 -111
-31 -30 -91 -37 -138 -18 -30 13 -39 23 -48 56 -6 22 -10 43 -9 48 1 4 1 32 0
61 l-1 52 -45 0 c-32 0 -47 -5 -52 -16z"/>
<path d="M5167 1583 c-4 -3 -7 -71 -7 -150 l0 -143 170 0 c182 0 217 7 240 51
16 29 3 77 -26 96 l-25 16 21 22 c40 42 19 90 -45 105 -46 11 -318 13 -328 3z
m262 -59 c29 -6 41 -26 23 -38 -4 -2 -48 -6 -97 -7 l-90 -2 -3 26 c-3 27 -3
27 66 27 38 0 84 -3 101 -6z m36 -144 c0 -25 -1 -25 -102 -28 l-103 -3 0 31 0
31 103 -3 c101 -3 102 -3 102 -28z"/>
<path d="M3870 1435 l0 -145 45 0 45 0 0 55 c0 44 3 55 16 55 9 0 45 -25 79
-55 61 -53 65 -55 121 -55 l59 0 -83 76 c-45 42 -82 80 -82 84 0 5 34 35 76
67 l75 58 -52 3 c-48 3 -56 0 -110 -42 -33 -25 -68 -46 -79 -46 -17 0 -20 7
-20 45 l0 45 -45 0 -45 0 0 -145z"/>

</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
